<template>
    <div>
        <div v-if="authStatus" class="container mt-5">
            <div v-if="this.isHoliday" class="w-100 d-flex align-items-center justify-content-center"
                style="margin-bottom: 25px;">
                <h4 class="text-danger">Market is {{ this.holidayStatus }} due to {{ this.holidayReason }}.</h4>
            </div>

            <div v-if="!this.ibkrServerStatus && this.ibkrClientStatus"
                class="w-100 d-flex align-items-center justify-content-center" style="margin-bottom: 25px;">
                <h4 class="text-danger">IBKR Gateway or TWS is currently connected on another device or platform. Please
                    verify your server gateway connection before using this webpage.</h4>
            </div>

            <div class="w-100 d-flex align-items-start justify-content-between">
                <div style="margin-bottom: 25px; margin-top: 9px;">
                    <h6 class="mb-1">
                        User Status :
                        <span :style="{ color: 'green' }">
                            {{ 'logged' }}
                        </span>
                        <svg @click="logout" xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                            viewBox="0 0 24 24" class="mx-2 bi bi-arrow-repeat"
                            style="cursor: pointer; margin-bottom: 2px">
                            <path fill="#555"
                                d="M13 2v10h-2V2zm3.78 1.728l.809.589a9.5 9.5 0 1 1-11.178 0l.808-.59l1.178 1.617l-.808.59a7.5 7.5 0 1 0 8.822 0l-.808-.59z" />
                        </svg>
                    </h6>

                    <h6 class="mb-1">
                        IBKR Client Status :
                        <span :style="{ color: ibkrClientStatus ? 'green' : 'red' }">
                            {{ ibkrClientStatus ? 'online' : 'offline' }}
                        </span>
                        <svg v-if="!ibkrClientStatus" xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                            fill="currentColor" class="mx-2 bi bi-arrow-repeat" :class="{ spinning }"
                            style="cursor: pointer" viewBox="0 0 16 16" @click="triggerSpin">
                            <path
                                d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41m-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9" />
                            <path fill-rule="evenodd"
                                d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5 5 0 0 0 8 3M3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9z" />
                        </svg>
                    </h6>

                    <h6 class="mb-1">
                        IBKR Server Status :
                        <span :style="{ color: ibkrServerStatus ? 'green' : 'red' }">
                            {{ ibkrServerStatus ? 'online' : 'offline' }}
                        </span>
                    </h6>
                </div>

                <div class="w-auto d-flex align-items-center justify-content-end" style="margin-bottom: 25px;">
                    <div class="d-flex align-items-center">
                        <span style="margin-right: 10px">Base Value Increase Notification : </span>
                        <label class="switch">
                            <input type="checkbox" v-model="enableCallBaseNotification">
                            <span class="slider"></span>
                        </label>
                    </div>
                    <div class="d-flex align-items-center" style="margin-left: 15px;">
                        <span style="margin-right: 10px">Base Value Decrease Notification : </span>
                        <label class="switch">
                            <input type="checkbox" v-model="enablePutBaseNotification">
                            <span class="slider"></span>
                        </label>
                    </div>
                    <div v-if="this.enableCallBaseNotification || this.enablePutBaseNotification"
                        class="d-flex align-items-center" style="margin-left: 15px;">
                        <label for="baseDifference" style="margin-right: 5px">Set Base Difference :</label>
                        <select v-model="selectedBaseDifference" id="number-base-difference"
                            @change="console.log(selectedBaseDifference)">
                            <option v-for="number in baseDifferenceLimits" :key="number" :value="number">
                                {{ number }}
                            </option>
                        </select>
                    </div>
                    <div class="d-flex align-items-center" style="margin-left: 15px;">
                        <span style="margin-right: 10px;">Show Data Table :</span>
                        <label class="switch">
                            <input type="checkbox" v-model="toggleDataTable">
                            <span class="slider"></span>
                        </label>
                    </div>
                </div>
            </div>

            <!-- Data Tables for Call and Put sides -->
            <div v-if="!toggleDataTable" id="dataTable" style="margin-top: 45px;">
                <div>
                    <div style="margin-bottom: 25px;">
                        <div class="d-flex align-items-center justify-content-end mb-4">

                            <div class="d-flex align-items-center" style="margin-left: 15px;">
                                <span style="margin-right: 5px">Call Difference Notification :</span>
                                <label class="switch">
                                    <input type="checkbox" v-model="enableCallNotification">
                                    <span class="slider"></span>
                                </label>
                            </div>
                            <div v-if="this.enableCallNotification" class="d-flex align-items-center"
                                style="margin-left: 15px;">
                                <label for="callSound" style="margin-right: 5px">Select Sound :</label>
                                <select id="callSound" v-model="selectedCallSound" @change="saveCallSound">
                                    <option v-for="sound in availableSounds" :key="sound" :value="sound">{{
                                        sound.substring(0,
                                            sound.lastIndexOf('.')) }}</option>
                                </select>
                            </div>
                        </div>

                        <DataTable side="CALL" :latestData="latestData" :selectedLimit="selectedCallLimit"
                            :limits="callLimits" @update:selectedLimit="saveCallLimit" />

                        <div class="d-flex flex-column align-items-center justify-content-center">
                            <div>
                                <div class="d-flex align-items-center">
                                    <p>
                                        Put Strike: <b>{{ this.latestData?.callput1 || 0 }}</b>
                                    </p>
                                    <p style="margin-right: 10px; margin-left: 10px;">
                                        Ask Price: <b>{{ this.latestData?.callputAskPrice1 || 0 }}</b>
                                    </p>
                                    <p>
                                        Bid Price: <b>{{ this.latestData?.callputBidPrice1 || 0 }}</b>
                                    </p>
                                </div>
                            </div>

                            <div class="d-flex align-items-center justify-content-center mb-4">
                                <div class="mx-2">
                                    <label for="number-select-call" style="margin-right: 5px">Select a size :</label>
                                    <select v-model="selectedCallSize" id="number-select-call">
                                        <option v-for="number in orderSizeLimits" :key="number" :value="number">
                                            {{ number }}
                                        </option>
                                    </select>
                                </div>

                                <div class="mx-2">
                                    <label for="order-type" style="margin-right: 5px">Select a order type :</label>
                                    <select v-model="selectedOrderCallType" id="order-type">
                                        <option v-for="orderType in orderTypeLimits" :key="orderType"
                                            :value="orderType">
                                            {{ orderType }}
                                        </option>
                                    </select>
                                </div>

                                <div v-if="this.selectedOrderCallType === 'TRAIL_VALUE'" class="mx-2">
                                    <label for="trail-type-call-value" style="margin-right: 5px">Select a Call Trail
                                        Value
                                        :</label>
                                    <select v-model="selectedCallTrailValue" id="trail-type-call-value">
                                        <option v-for="value in trailValue" :key="value" :value="value">
                                            {{ value }}
                                        </option>
                                    </select>
                                </div>

                                <div v-if="this.selectedOrderCallType === 'TRAIL_PERCENTAGE'" class="mx-2">
                                    <label for="trail-type-call" style="margin-right: 5px">Select a Call Trail
                                        Percentage
                                        :</label>
                                    <select v-model="selectedCallTrailPercentage" id="trail-type-call">
                                        <option v-for="percentage in trailPercentage" :key="percentage"
                                            :value="percentage">
                                            {{ percentage }}
                                        </option>
                                    </select>
                                </div>

                                <b-button class="mx-2 btn-success" :disabled="!ibkrServerStatus"
                                    @click="buyOrder('CALL', this.selectedOrderCallType, false)">Buy</b-button>
                                <b-button class="mx-2 btn-danger" :disabled="!ibkrServerStatus"
                                    @click="sellOrder('CALL', this.selectedOrderCallType, false)">Sell</b-button>
                            </div>
                        </div>

                    </div>
                    <div>
                        <div class="d-flex align-items-center justify-content-end mb-4">
                            <div class="d-flex align-items-center" style="margin-left: 15px;">
                                <span style="margin-right: 5px">Put Difference Notification :</span>
                                <label class="switch">
                                    <input type="checkbox" v-model="enablePutNotification">
                                    <span class="slider"></span>
                                </label>
                            </div>
                            <div v-if="enablePutNotification" class="d-flex align-items-center"
                                style="margin-left: 15px;">
                                <label for="putSound" style="margin-right: 5px">Select Sound :</label>
                                <select id="putSound" v-model="selectedPutSound" @change="savePutSound">
                                    <option v-for="sound in availableSounds" :key="sound" :value="sound">{{
                                        sound.substring(0,
                                            sound.lastIndexOf('.')) }}</option>
                                </select>
                            </div>
                        </div>

                        <DataTable side="PUT" :latestData="latestData" :selectedLimit="selectedPutLimit"
                            :limits="putLimits" @update:selectedLimit="savePutLimit" />


                        <div class="d-flex flex-column align-items-center justify-content-center mb-4">
                            <div>
                                <div class="d-flex align-items-center">
                                    <p>
                                        Call Strike: <b>{{ this.latestData?.putcall1 || 0 }}</b>
                                    </p>
                                    <p style="margin-right: 10px; margin-left: 10px;">
                                        Ask Price: <b>{{ this.latestData?.putcallAskPrice1 || 0 }}</b>
                                    </p>
                                    <p>
                                        Bid Price: <b>{{ this.latestData?.putcallBidPrice1 || 0 }}</b>
                                    </p>
                                </div>
                            </div>

                            <div class="d-flex align-items-center justify-content-center mb-4">
                                <div class="mx-2">
                                    <label for="number-select-put" style="margin-right: 5px">Select a size :</label>
                                    <select v-model="selectedPutSize" id="number-select-put">
                                        <option v-for="number in orderSizeLimits" :key="number" :value="number">
                                            {{ number }}
                                        </option>
                                    </select>
                                </div>

                                <div class="mx-2">
                                    <label for="order-type-put" style="margin-right: 5px">Select a order type
                                        :</label>
                                    <select v-model="selectedOrderPutType" id="order-type-put">
                                        <option v-for="orderType in orderTypeLimits" :key="orderType"
                                            :value="orderType">
                                            {{ orderType }}
                                        </option>
                                    </select>
                                </div>

                                <div v-if="this.selectedOrderPutType === 'TRAIL_VALUE'" class="mx-2">
                                    <label for="trail-type-put-value" style="margin-right: 5px">Select a Put Trail
                                        Value
                                        :</label>
                                    <select v-model="selectedPutTrailValue" id="trail-type-put-value">
                                        <option v-for="value in trailValue" :key="value" :value="value">
                                            {{ value }}
                                        </option>
                                    </select>
                                </div>

                                <div v-if="this.selectedOrderPutType === 'TRAIL_PERCENTAGE'" class="mx-2">
                                    <label for="trail-type-put" style="margin-right: 5px">Select a Put Trail Percentage
                                        :</label>
                                    <select v-model="selectedPutTrailPercentage" id="trail-type-put">
                                        <option v-for="percentage in trailPercentage" :key="percentage"
                                            :value="percentage">
                                            {{ percentage }}
                                        </option>
                                    </select>
                                </div>

                                <b-button class="mx-2 btn-success" :disabled="!ibkrServerStatus"
                                    @click="buyOrder('PUT', this.selectedOrderPutType, false)">Buy</b-button>
                                <b-button class="mx-2 btn-danger" :disabled="!ibkrServerStatus"
                                    @click="sellOrder('PUT', this.selectedOrderPutType, false)">Sell</b-button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="w-100 mb-4">
                    <div class="w-100 d-flex align-items-center justify-content-end" style="margin-bottom: 25px;">
                        <div class="d-flex align-items-center" style="margin-left: 15px;">
                            <span style="margin-right: 10px;">Show Future Order Details :</span>
                            <label class="switch">
                                <input type="checkbox" v-model="toggleFutureTable">
                                <span class="slider"></span>
                            </label>
                        </div>
                    </div>

                    <table class="table table-bordered table-striped table-hover table-responsive">
                        <thead>
                            <tr>
                                <td colspan="8" style="text-align: center; font-weight: bold;">
                                    <h5>{{ this.toggleFutureTable ? 'Future Order Details'
                                        : 'Order Details' }}</h5>
                                </td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td><strong>{{ this.toggleFutureTable ? 'Select Expiry Date' : 'Expiry Date' }}</strong>
                                </td>
                                <td><strong>{{ this.toggleFutureTable ? 'Select Call Strike' : 'Call Strike' }}</strong>
                                </td>
                                <td><strong>{{ this.toggleFutureTable ? 'Select Put Strike' : 'Put Strike' }}</strong>
                                </td>
                                <td><strong>Select Order Type</strong></td>
                                <td><strong>Quantity</strong></td>
                                <td><strong>Trail Stop Loss</strong></td>
                            </tr>
                            <tr>
                                <td v-if="!toggleFutureTable">
                                    {{ this.selectedExpiryDate }}
                                </td>
                                <td v-if="toggleFutureTable">
                                    <input type="date" v-model="selectedExpiryDate" id="date-select-expiry"
                                        @change="handleExpiryDate">
                                </td>
                                <td v-if="!toggleFutureTable">{{ this.latestData?.call || 0 }}</td>
                                <td v-if="toggleFutureTable">
                                    <div class="mx-2">
                                        <!-- :fetchNewData="() => this.fetchFutureData(true, 'call')"  -->
                                        <CustomSelect :options="this.futureCallDataLimits.map((data) => data)"
                                            v-model="this.selectedFutureCallStrike" @change="handleFutureCallChange"
                                            :fetchNewData="() => console.log('new data fetch called successfully for call')" />
                                    </div>
                                </td>
                                <td v-if="!toggleFutureTable">{{ this.latestData?.put || 0 }}</td>
                                <td v-if="toggleFutureTable">
                                    <div class="mx-2">
                                        <!-- :fetchNewData="() => this.fetchFutureData(true, 'put')"  -->
                                        <CustomSelect :options="this.futurePutDataLimits.map((data) => data)"
                                            v-model="this.selectedFuturePutStrike" @change="handleFuturePutChange"
                                            :fetchNewData="() => console.log('new data fetch called successfully for put')" />
                                    </div>
                                </td>
                                <td>
                                    <div class="mx-2">
                                        <select v-model="selectedOrderType">
                                            <option v-for="orderType in orderTypeLimits" :key="orderType"
                                                :value="orderType">
                                                {{ orderType }}
                                            </option>
                                        </select>
                                    </div>
                                </td>
                                <td>
                                    <div class="mx-2">
                                        <label for="number-select" style="margin-right: 5px">Select a size :</label>
                                        <select v-model="selectedOrderSize" id="number-select">
                                            <option v-for="number in orderSizeLimits" :key="number" :value="number">
                                                {{ number }}
                                            </option>
                                        </select>
                                    </div>
                                </td>
                                <td>
                                    <div v-if="this.selectedOrderType === 'TRAIL_VALUE'" class="mx-2">
                                        <label for="trail-type-value" style="margin-right: 5px">Select a Put Trail
                                            Value
                                            :</label>
                                        <select v-model="selectedTrailValue" id="trail-type-value">
                                            <option v-for="value in trailValue" :key="value" :value="value">
                                                {{ value }}
                                            </option>
                                        </select>
                                    </div>

                                    <div v-if="this.selectedOrderType === 'TRAIL_PERCENTAGE'" class="mx-2">
                                        <label for="trail-type" style="margin-right: 5px">Select a Trail Percentage
                                            :</label>
                                        <select v-model="selectedTrailPercentage" id="trail-type">
                                            <option v-for="percentage in trailPercentage" :key="percentage"
                                                :value="percentage">
                                                {{ percentage }}
                                            </option>
                                        </select>
                                    </div>

                                    <div v-if="this.selectedOrderType === 'MKT'">N/A</div>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <div class="w-100 mb-4 d-flex align-items-center justify-content-between">
                        <div>
                            <div class="d-flex align-items-center">
                                <p>
                                    Call Strike: <b>{{ this.toggleFutureTable ? this.selectedFutureCallStrike :
                                        this.latestData?.call || 0 }}</b>
                                </p>
                                <p style="margin-right: 10px; margin-left: 10px;">
                                    Ask Price: <b>{{ this.toggleFutureTable ? this.selectedFutureCallAskPrice || 0 :
                                        this.latestData?.callAskPrice || 0 }}</b>
                                </p>
                                <p>
                                    Bid Price: <b>{{ this.toggleFutureTable ? this.selectedFutureCallBidPrice || 0 :
                                        this.latestData?.callBidPrice || 0 }}</b>
                                </p>
                            </div>
                            <div class="d-flex align-items-center">
                                <b-button :disabled="!ibkrServerStatus" class="btn-success"
                                    @click="buyOrder('CALL', this.selectedOrderType, true)">Buy</b-button>
                                <b-button :disabled="!ibkrServerStatus" class="btn-danger" style="margin-left: 10px;"
                                    @click="sellOrder('CALL', this.selectedOrderType, true)">Sell</b-button>
                            </div>
                        </div>
                        <div class="d-flex flex-column align-items-end justify-content-center">
                            <div class="d-flex align-items-center">
                                <p>
                                    Put Strike: <b>{{ this.toggleFutureTable ? this.selectedFuturePutStrike :
                                        this.latestData?.put || 0 }}</b>
                                </p>
                                <p style="margin-right: 10px; margin-left: 10px;">
                                    Ask Price: <b>{{ this.toggleFutureTable ? this.selectedFuturePutAskPrice || 0 :
                                        this.latestData?.putAskPrice || 0 }}</b>
                                </p>
                                <p>
                                    Bid Price: <b>{{ this.toggleFutureTable ? this.selectedFuturePutBidPrice || 0 :
                                        this.latestData?.putBidPrice || 0 }}</b>
                                </p>
                            </div>

                            <div class="d-flex align-items-center">
                                <b-button :disabled="!ibkrServerStatus" class="btn-success"
                                    @click="buyOrder('PUT', this.selectedOrderType, true)">Buy</b-button>
                                <b-button :disabled="!ibkrServerStatus" class="btn-danger" style="margin-left: 10px;"
                                    @click="sellOrder('PUT', this.selectedOrderType, true)">Sell</b-button>
                            </div>
                        </div>
                    </div>
                </div>

                <div style="margin-top: 75px;">
                    <OrderData />
                </div>
            </div>

            <!-- Video components -->
            <div v-if="toggleDataTable">
                <FilterData />
            </div>

            <!-- Audio player for notifications -->
            <audio ref="audioPlayer"></audio>
        </div>

        <div class="auth-container" v-if="!authStatus">
            <AuthLogin :authStatus="authStatus" :handleAuthStatus="handleAuthStatus" :authLoading="authLoading" />
        </div>
    </div>
</template>

<script>
import reconnectSocket from '@/plugins/socket';
import { useToast } from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import DataTable from './DataTable.vue';
import FilterData from './FilterData.vue';
import OrderData from './OrderData.vue';
import VueCookie from 'vue-cookie';
import moment from 'moment-timezone';
import CustomSelect from './CustomSelect.vue';
import AuthLogin from './AuthLogin.vue';

// Initialize toast notifications
const $toast = useToast();

export default {
    components: {
        DataTable,
        FilterData,
        OrderData,
        CustomSelect,
        AuthLogin
    },
    data() {
        return {
            // User Auth
            authStatus: false,
            authLoading: false,
            form: {
                email: '',
                password: ''
            },
            // Page UI
            latestData: null,
            socket: null,
            toggleDataTable: true,
            // Notification Data
            notification: null,
            enablePutNotification: true,
            enableCallNotification: true,
            enableCallBaseNotification: true,
            enablePutBaseNotification: true,
            selectedBaseValuePoint: null,
            continuesPlay: 0,
            isUpSound: false,
            availableSounds: [
                "notification_01.mp3",
                "notification_02.mp3",
                "notification_03.mp3",
                "notification_04.mp3",
                "notification_05.mp3",
                "notification_06.mp3",
                "notification_07.mp3",
                "notification_08.mp3",
                "notification_09.mp3",
                "notification_10.mp3",
                "notification_11.mp3",
                "notification_12.wav",
                "notification_13.wav"
            ],
            selectedCallSound: 'notification_12.wav',
            selectedPutSound: 'notification_13.wav',
            selectedBaseUpSound: 'water_drip.mp3',
            selectedBaseDownSound: 'piano_1.mp3',
            soundCache: {},
            baseDifferenceLimits: this.generateFloatNumbers(),
            selectedBaseDifference: '10',
            putLimits: this.generateLimits(),
            selectedPutLimit: '0.00',
            callLimits: this.generateLimits(),
            selectedCallLimit: '0.00',
            // Order Data 
            // IBKR status
            ibkrClientStatus: false,
            ibkrServerStatus: false,
            spinning: false,
            // Select Order Size
            orderSizeLimits: this.generateNumbers(0, 20, 1),
            selectedCallSize: 1,
            selectedPutSize: 1,
            selectedOrderSize: 1,
            // Select Order Type
            orderTypeLimits: ['MKT', 'TRAIL_VALUE'],
            selectedOrderCallType: 'MKT',
            selectedOrderPutType: 'MKT',
            selectedOrderType: 'MKT',
            // Select Order Strike
            selectedCallStrike: 0,
            selectedPutStrike: 0,
            selectedFutureStrike: 0,
            // Select Order Trailing Percentage
            trailPercentage: this.generateFloatNumbers(),
            trailValue: this.generateNumbers(0, 10, 0.5),
            selectedCallTrailPercentage: 10,
            selectedPutTrailPercentage: 10,
            selectedTrailPercentage: 10,
            selectedCallTrailValue: 10,
            selectedPutTrailValue: 10,
            selectedTrailValue: 10,
            // Order Future Data
            toggleFutureTable: false,
            selectedExpiryDate: moment().format('YYYY-MM-DD'),
            futureCallDataLimits: [],
            selectedFutureCallStrike: 0,
            selectedFutureCallAskPrice: 0,
            selectedFutureCallBidPrice: 0,
            futureCallNextUrl: null,
            futurePutDataLimits: [],
            selectedFuturePutStrike: 0,
            selectedFuturePutAskPrice: 0,
            selectedFuturePutBidPrice: 0,
            futurePutNextUrl: null,
            // Holiday check
            isHoliday: false,
            holidayStatus: 'closed',
            holidayReason: 'holiday',
        };
    },
    mounted() {
        const notification = this.$route.query.notification;
        this.notification = notification === 'false' ? false : true;
        this.connectSocketIO();
        this.requestNotificationPermission();
        this.selectedCallSound = VueCookie.get('callSound') || 'notification_12.wav';
        this.selectedPutSound = VueCookie.get('putSound') || 'notification_13.wav';
        this.fetchFutureData(false, 'all')
        this.userValidate()
    },
    watch: {
        selectedBaseDifference(newVal) {
            console.log(newVal); // Log or handle updated value here
        },

        async authStatus(newStatus) {
            if (newStatus) {
                this.connectSocketIO();
            } else if (this.socket) {
                this.socket.disconnect();
                console.log('Socket.IO disconnected due to authStatus change');
            }
        },
    },
    methods: {
        // Request permission for browser notifications
        requestNotificationPermission() {
            if ('Notification' in window) {
                Notification.requestPermission().then(permission => {
                    console.log(`Notification permission ${permission}.`);
                });
            } else {
                console.log('This browser does not support notifications.');
            }
        },

        handleAuthStatus(value) {
            this.authStatus = value
        },

        async userValidate() {
            try {
                this.authLoading = true
                if (!this.authStatus && VueCookie.get('authToken') !== null) {
                    const url = `${process.env.VUE_APP_BACKEND_URL}/api/getUserByToken`;

                    try {
                        const response = await fetch(url, {
                            method: 'GET', // Set method to POST
                            headers: {
                                'Content-Type': 'application/json',
                                'authorization': `Bearer ${VueCookie.get('authToken')}`
                            },
                        });

                        if (response.ok) {
                            const data = await response.json();
                            console.log('Login successful', data);
                            this.handleAuthStatus(true)
                        } else {
                            console.error('Failed to login:', response.status);
                        }
                    } catch (error) {
                        console.error('Error during login request:', error);
                    }
                } else {
                    this.handleAuthStatus(false)
                }
                this.authLoading = false
            } catch (error) {
                console.error('Authentication error:', error);
            }
        },

        logout() {
            VueCookie.delete('authToken')
            window.location.reload();
        },

        generateLimits() {
            return Array.from({ length: 21 }, (_, i) => (i * 0.5).toFixed(2));
        },

        generateNumbers(start = 0, end, inc) {
            let numbers = [];
            for (let i = start; i <= end; i += inc) {
                numbers.push(i);
            }
            return numbers;
        },

        generateFloatNumbers() {
            let numbers = [];

            for (let i = 2; i <= 10; i += 1) {
                numbers.push(i);
            }

            for (let i = 20; i <= 100; i += 10) {
                numbers.push(i);
            }

            return numbers;
        },

        async triggerSpin() {
            const url = `${process.env.VUE_APP_BACKEND_URL}/api/reconnect`;
            this.spinning = true;

            try {
                // Send the reconnect request
                const response = await fetch(url);

                // Check for a valid response
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                // Parse the JSON response
                const result = await response.json();

                // Handle response based on status
                if (result.status === 200) {
                    this.showToast("Reconnect Request Sent Successfully!", "success");
                }
            } catch (error) {
                console.error("Error in triggerSpin:", error.message);
                this.showToast(
                    "Failed to send reconnect request.",
                    "error"
                );
            } finally {
                // Stop spinning regardless of success or failure
                setTimeout(() => {
                    this.spinning = false;
                }, 1500)
            }
        },

        // Determine if a toast should be shown
        shouldShowToast(type, priceDifference) {
            const limit = type === 'call' ? this.selectedCallLimit : this.selectedPutLimit;
            return limit !== '0.00' && Math.max(priceDifference, 0) === Number(limit);
        },

        // Send browser notification
        sendNotification(title, message) {
            if ('Notification' in window && Notification.permission === 'granted') {
                const notification = new Notification(title, { body: message });
                notification.onclick = event => event.preventDefault();
            } else {
                console.log('Notifications are not permitted or supported.');
            }
        },

        showToast(message, type) {
            $toast.open({
                message,
                type,
                duration: 3000,
                dismissible: true,
                queue: true,
            });
        },

        saveSound(type, sound) {
            try {
                // Check if the selected sound is the same as the other sound type
                const otherType = type === 'callSound' ? 'putSound' : 'callSound';
                const otherSound = VueCookie.get(otherType);

                // Store the previous sound before making changes
                const previousSound = VueCookie.get(type);

                if (sound === otherSound) {
                    VueCookie.set(type, otherSound, { expires: '12h' });
                    // Revert the selected sound to the previous sound
                    this[type] = previousSound;

                    alert(`The selected sound is already used for ${otherType}. Please choose a different sound.`);
                    return; // Prevent saving the same sound
                }
                // Save the sound to the cookie
                VueCookie.set(type, sound, { expires: '12h' });
                // console.log(`${type} sound saved successfully`);

            } catch (error) {
                console.error(`Failed to save ${type} sound:`, error);
            }
        },

        // Save selected call sound to cookie
        saveCallSound() {
            this.saveSound('callSound', this.selectedCallSound);
            this.playSound(this.selectedCallSound, 1);
        },

        // Save selected put sound to cookie
        savePutSound() {
            this.saveSound('putSound', this.selectedPutSound);
            this.playSound(this.selectedPutSound, 1);
        },

        // Utility function to ensure sounds are cached and preloaded
        preloadSound(sound) {
            if (!this.soundCache[sound]) {
                const audio = new Audio(`/assets/${sound}`);
                audio.load();
                this.soundCache[sound] = audio;
            }
            return this.soundCache[sound];
        },

        // Improved playSound function
        playSound(sound, volume) {
            const audioPlayer = this.preloadSound(sound); // Preload and get cached audio
            if (!audioPlayer) return;

            // Set volume within the valid range
            audioPlayer.volume = Math.max(0, Math.min(volume, 1));

            // Play sound if it's not currently playing
            if (audioPlayer.paused || audioPlayer.currentTime === 0) {
                audioPlayer.currentTime = 0; // Reset to start
                audioPlayer.play()
                    .then(() => {
                        console.log(`Playing sound: ${sound} at volume: ${audioPlayer.volume}`);
                    })
                    .catch(error => console.error('Error playing sound:', error));
            } else {
                // Reset and replay if currently playing
                audioPlayer.pause();
                audioPlayer.currentTime = 0;
                audioPlayer.play()
                    .then(() => {
                        console.log(`Replaying sound: ${sound} at volume: ${audioPlayer.volume}`);
                    })
                    .catch(error => console.error('Error playing sound:', error));
            }
        },

        // Improved playSoundRepeatedly function
        playSoundRepeatedly(sound, volume, repeat) {
            // console.log(sound, volume, repeat)
            const audioPlayer = this.preloadSound(sound);
            if (!audioPlayer) return;

            // Set volume within the valid range
            audioPlayer.volume = Math.max(0, Math.min(volume, 1));

            const totalDuration = 1000; // Total time to play sounds (1 second)
            const interval = Math.floor(totalDuration / repeat); // Interval between repeats
            let playCount = 0;

            const playNextSound = () => {
                if (playCount >= repeat) return;

                audioPlayer.currentTime = 0; // Reset to start for each repeat
                audioPlayer.play()
                    .then(() => {
                        playCount++;
                        if (playCount < repeat) {
                            // Schedule the next playback only if repeat count not reached
                            setTimeout(playNextSound, interval);
                        }
                    })
                    .catch(error => console.error('Error playing sound:', error));
            };

            playNextSound(); // Start playing
        },

        // Show toast notification with cooldown
        makeToast(type, message) {
            $toast.open({
                message,
                type: 'default',
                duration: 3000,
                dismissible: true,
                queue: true,
            });

            switch (type) {
                case 'call':
                    if (this.enableCallNotification) {
                        this.playSound(this.selectedCallSound, 1)
                        this.sendNotification('Call Difference', message)
                    }
                    break;
                case 'put':
                    if (this.enablePutNotification) {
                        this.playSound(this.selectedPutSound, 1)
                        this.sendNotification('Put Difference', message)
                    }
                    break;
                default:
                    console.log('sendNotification', message, type);
            }

            const cookieName = type === 'call' ? 'notShowCall' : 'notShowPut';
            VueCookie.set(cookieName, 'true', { expires: new Date(new Date().getTime() + process.env.VUE_APP_NOTIFICATION_DELAY * 1000) });
        },

        // Save and emit new Put limit
        savePutLimit(limit) {
            this.selectedPutLimit = limit;
            this.socket && this.socket.emit('changePutLimit', limit);
        },

        // Save and emit new Call limit
        saveCallLimit(limit) {
            this.selectedCallLimit = limit;
            this.socket && this.socket.emit('changeCallLimit', limit);
        },

        handlePolygonData(data) {
            const { callaskPriceDifference, putaskPriceDifference, baseValue } = data;

            if (this.enableCallBaseNotification || this.enablePutBaseNotification) {
                if (!this.previousBaseValue) {
                    this.previousBaseValue = baseValue;
                } else {
                    const baseDiff = Number(this.selectedBaseDifference) / 100;
                    const difference = Number(baseValue) - Number(this.previousBaseValue);
                    const repeatCount = Math.max(1, Math.floor(Math.abs(difference) / baseDiff));

                    if (this.enableCallBaseNotification && difference >= baseDiff) {
                        this.playSoundRepeatedly(this.selectedBaseUpSound, 1, Math.min(repeatCount, 5));
                    }

                    if (this.enablePutBaseNotification && difference <= -baseDiff) {
                        this.playSoundRepeatedly(this.selectedBaseDownSound, 1, Math.min(repeatCount, 5));
                    }

                    this.previousBaseValue = baseValue;
                }
            } else {
                this.previousBaseValue = null;
            }

            const notShowCall = VueCookie.get('notShowCall');
            const notShowPut = VueCookie.get('notShowPut');

            if (this.shouldShowToast('call', callaskPriceDifference) && !notShowCall && this.notification && this.enableCallNotification) {
                this.makeToast('call', `Call side difference reached ${this.selectedCallLimit}`);
            }

            if (this.shouldShowToast('put', putaskPriceDifference) && !notShowPut && this.notification && this.enablePutNotification) {
                this.makeToast('put', `Put side difference reached ${this.selectedPutLimit}`);
            }
        },

        async fetchFutureData(isNext, type, date = moment().format('YYYY-MM-DD')) {
            try {
                let url = `${process.env.VUE_APP_BACKEND_URL}/api/getStrikeData?date=${date}`

                // console.log("fetchFutureData is called", isNext, type)

                if (isNext) {
                    if (type === 'call' && this.futureCallNextUrl) {
                        url = `${process.env.VUE_APP_BACKEND_URL}/api/getStrikeData?url=${this.futureCallNextUrl}`
                    } else if (type === 'put' && this.futurePutNextUrl) {
                        url = `${process.env.VUE_APP_BACKEND_URL}/api/getStrikeData?url=${this.futurePutNextUrl}`
                    } else {
                        return
                    }
                }

                const resp = await fetch(url);

                if (!resp.ok) {
                    throw new Error('Failed to fetch data');
                }

                const result = await resp.json();


                if (isNext) {
                    if (type === 'call') {
                        // console.log("fetchFutureData Call", result)
                        const modifiedCallData = [...this.futureCallDataLimits, ...result?.data?.call?.data]
                        this.futureCallNextUrl = result?.data?.call?.next_url
                        this.futureCallDataLimits = modifiedCallData
                    } else if (type === 'put') {
                        // console.log("fetchFutureData Put", result)
                        const modifiedPutData = [...this.futurePutDataLimits, ...result?.data?.put?.data]
                        this.futurePutNextUrl = result?.data?.put?.next_url
                        this.futurePutDataLimits = modifiedPutData
                    } else {
                        return
                    }
                } else {
                    // console.log("fetchFutureData Call", result?.data?.call?.data)
                    this.futureCallDataLimits = result?.data?.call?.data;
                    this.futureCallNextUrl = result?.data?.call?.next_url
                    this.selectedFutureCallStrike = 0
                    // console.log("fetchFutureData Put", result?.data?.put?.data)
                    this.futurePutDataLimits = result?.data?.put?.data
                    this.futurePutNextUrl = result?.data?.put?.next_url
                    this.selectedFuturePutStrike = 0
                }
            } catch (err) {
                this.error = err.message;  // Store any error message
            }
        },

        handleExpiryDate() {
            this.selectedExpiryDate !== "" && this.fetchFutureData(false, 'all', this.selectedExpiryDate)
        },

        handleFutureCallChange(value) {
            // console.log("fetchFutureData", value)
            this.selectedFutureCallStrike = value?.strike_price
            this.selectedFutureCallAskPrice = value?.askPrice
            this.selectedFutureCallBidPrice = value?.bidPrice
        },

        handleFuturePutChange(value) {
            // console.log("fetchFutureData", value)
            this.selectedFuturePutStrike = value?.strike_price
            this.selectedFuturePutAskPrice = value?.askPrice
            this.selectedFuturePutBidPrice = value?.bidPrice
        },

        async executeOrder(action, orderDetails) {
            console.log('OrderDetails', action, orderDetails)

            try {
                const response = await fetch(`${process.env.VUE_APP_BACKEND_URL}/api/orders/${action}`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(orderDetails),
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                const data = await response.json();
                this.showToast(data.message, 'success');
            } catch (error) {
                console.error(`Error placing ${action} order:`, error);
                this.showToast(`Error placing ${action} order: ${error.message}`, 'error');
            }
        },

        getOrderDetails(right, orderType, isNormalOrder) {
            const isPut = right === 'PUT';
            const isFutureTable = this.toggleFutureTable;
            const isTrailOrder = orderType === 'TRAIL_VALUE'

            console.log('orderDetails', right, orderType, isTrailOrder)
            console.log('orderDetails quantity', isNormalOrder, this.selectedOrderSize, this.selectedCallSize, this.selectedPutSize)

            return {
                symbol: 'SPX',
                quantity: isPut
                    ? !isNormalOrder ? this.selectedPutSize : this.selectedOrderSize
                    : !isNormalOrder ? this.selectedCallSize : this.selectedOrderSize,
                orderType,
                lmtPrice: isPut
                ? isFutureTable
                        ? !isNormalOrder ? Number(this.latestData?.putcallAskPrice1) : this.selectedFuturePutAskPrice
                        : !isNormalOrder ? Number(this.latestData?.putcallAskPrice1) : Number(this.latestData?.putAskPrice)
                    : isFutureTable
                        ? !isNormalOrder ? Number(this.latestData?.callputAskPrice1) : this.selectedFutureCallAskPrice
                        : !isNormalOrder ? Number(this.latestData?.callputAskPrice1) : Number(this.latestData?.callAskPrice),
                auxPrice: isPut
                    ? isFutureTable
                        ? isTrailOrder ? !isNormalOrder ? Number(this.selectedPutTrailValue) : Number(this.selectedTrailValue) : !isNormalOrder ? Number(this.latestData?.putcallAskPrice1) : this.selectedFuturePutAskPrice
                        : isTrailOrder ? !isNormalOrder ? Number(this.selectedPutTrailValue) : Number(this.selectedTrailValue) : !isNormalOrder ? Number(this.latestData?.putcallAskPrice1) : Number(this.latestData?.putAskPrice)
                    : isFutureTable
                        ? isTrailOrder ? !isNormalOrder ? Number(this.selectedCallTrailValue) : Number(this.selectedTrailValue) : !isNormalOrder ? Number(this.latestData?.callputAskPrice1) : this.selectedFutureCallAskPrice
                        : isTrailOrder ? !isNormalOrder ? Number(this.selectedCallTrailValue) : Number(this.selectedTrailValue) : !isNormalOrder ? Number(this.latestData?.callputAskPrice1) : Number(this.latestData?.callAskPrice),
                trailingPercent: isPut
                    ? isFutureTable
                        ? this.selectedTrailPercentage
                        : this.selectedPutTrailPercentage
                    : isFutureTable
                        ? this.selectedTrailPercentage
                        : this.selectedCallTrailPercentage,
                account: process.env.VUE_APP_ACCOUNT_ID,
                secType: 'OPT',
                exchange: 'SMART',
                strike: isPut
                    ? isFutureTable
                        ? !isNormalOrder ? this.selectedPutStrike : this.selectedFuturePutStrike
                        : !isNormalOrder ? this.selectedPutStrike : this.latestData?.put || 0
                    : isFutureTable
                        ? !isNormalOrder ? this.selectedCallStrike : this.selectedFutureCallStrike
                        : !isNormalOrder ? this.selectedCallStrike : this.latestData?.call || 0,
                expiry: isFutureTable
                    ? moment(this.selectedExpiryDate, 'YYYY/MM/DD').format('YYYYMMDD')
                    : moment(this.latestData?.date, 'MM/DD/YYYY').format('YYYYMMDD'),
                right: right === 'CALL' ? 'P' : 'C',
            };
        },

        async placeOrder(action, right, orderType, isNormalOrder) {
            const orderDetails = this.getOrderDetails(right, orderType, isNormalOrder);
            await this.executeOrder(action, orderDetails);
        },

        async buyOrder(right, orderType, isNormalOrder) {
            await this.placeOrder('buy', right, orderType, isNormalOrder);
        },

        async sellOrder(right, orderType, isNormalOrder) {
            await this.placeOrder('sell', right, orderType, isNormalOrder);
        },

        async cancelAllOrders() {
            try {
                const response = await fetch(`${process.env.VUE_APP_BACKEND_URL}/api/orders/cancelAll`, {
                    method: 'DELETE',
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                const data = await response.json();
                this.showToast(data.message, 'success');
            } catch (error) {
                console.error('Error canceling all orders:', error);
                this.showToast('Error canceling all orders', 'error');
            }
        },

        connectSocketIO() {
            this.socket = reconnectSocket()

            this.socket.on('connect', () => {
                // console.log('Socket.IO connection established.');
                this.socket.emit('message', 'Hello server, send me polyData!');
            });

            this.socket.on('holiday', data => {
                this.isHoliday = data?.isHoliday
                this.holidayStatus = data?.holidayStatus
                this.holidayReason = data?.holidayReason
            })

            this.socket.on('polygonData', data => {
                this.latestData = data;
                this.selectedCallStrike = data?.callput1
                this.selectedPutStrike = data?.putcall1
                this.handlePolygonData(data);
            });

            this.socket.on('callLimit', data => {
                if (this.selectedCallLimit !== data) {
                    // console.log('Changed call limit:', this.selectedCallLimit, data);
                    this.selectedCallLimit = data;
                }
            });

            this.socket.on('putLimit', data => {
                if (this.selectedPutLimit !== data) {
                    // console.log('Changed put limit:', this.selectedPutLimit, data);
                    this.selectedPutLimit = data;
                }
            });

            this.socket.on('orderStatus', data => {
                if (data?.orderId) {
                    let message = `${data?.orderAction} ${data?.quantity} ${data?.symbol} @ ${data?.strike} is ${data?.orderStatus}`

                    switch (data?.orderStatus) {
                        case 'Cancelled':
                            this.showToast(message, 'error')
                            break;
                        case 'Pending':
                            this.showToast(message, 'info')
                            break;
                        case 'Filled':
                            message = `${data?.orderAction} ${data?.quantity} ${data?.symbol} @ ${data?.strike} is ${data?.orderStatus} with ${data?.avgFillPrice}`
                            this.showToast(message, 'success')
                            break;
                        case 'Inactive':
                            this.showToast(message, 'error')
                            break;
                        case 'PreSubmitted':
                            this.showToast(message, 'info')
                            break;
                        case 'Submitted':
                            this.showToast(message, 'success')
                            break;
                    }
                }
            })

            this.socket.on('orderError', data => {
                this.showToast(data, 'error')
            })

            this.socket.on('ibkrStatus', data => {
                console.log('IBKR status', data)
                this.ibkrClientStatus = data?.ibkrClientStatus
                this.ibkrServerStatus = data?.ibkrServerStatus
            })

            this.socket.on('disconnect', () => {
                // console.log('Socket.IO connection closed.');
            });

            this.socket.on('error', error => {
                console.error('Socket.IO error:', error);
            });
        }
    }
};
</script>

<style scoped>
.spinning {
    animation: spin 1s linear infinite;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked+.slider {
    background-color: #2196F3;
}

input:focus+.slider {
    box-shadow: 0 0 1px #2196F3;
}

input:checked+.slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

select {
    height: 38px;
}
</style>