<template>
    <div class="custom-select-wrapper" ref="selectWrapper">
        <div class="custom-select" @click="toggleDropdown">
            <span>{{ displayValue }}</span>
            <i class="arrow"></i>
        </div>
        <div v-if="isOpen" class="dropdown" ref="dropdown" @scroll="handleScroll">
            <div v-for="option in options" :key="option" class="dropdown-option" @click="selectOption(option)"
                :class="{ 'selected': option.strike_price === modelValue }">
                {{ option?.strike_price }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        options: {
            type: Array,
            required: true
        },
        modelValue: {
            type: [String, Number],
            required: true
        },
        fetchNewData: {
            type: Function,
            required: false
        }
    },

    emits: ['update:modelValue', 'change'],

    data() {
        return {
            isOpen: false,
            debounceTimer: null,
        };
    },

    computed: {
        displayValue() {
            return this.modelValue || 'Select an option'
        }
    },

    methods: {
        toggleDropdown() {
            this.isOpen = !this.isOpen;
            if (this.isOpen) {
                this.scrollToSelectedOption();
            }
        },

        scrollToSelectedOption() {
            this.$nextTick(() => {
                if (!this.$refs.dropdown) return;

                // Find the selected option
                const selectedOption = this.$refs.dropdown.querySelector('.selected');
                if (selectedOption) {
                    const dropdown = this.$refs.dropdown;
                    const offsetTop = selectedOption.offsetTop;

                    // Scroll dropdown to the selected option
                    dropdown.scrollTo({
                        top: offsetTop - dropdown.clientHeight / 2 + selectedOption.clientHeight / 2,
                        behavior: 'smooth',
                    });
                }
            });
        },


        selectOption(option) {
            this.$emit('update:modelValue', option?.strike_price);
            this.$emit('change', option);
            this.isOpen = false;
        },

        closeDropdown(event) {
            if (this.$refs.selectWrapper && !this.$refs.selectWrapper.contains(event.target)) {
                this.isOpen = false;
            }
        },

        handleScroll() {
            if (!this.$refs.dropdown) return;

            const dropdown = this.$refs.dropdown;
            const scrollPosition = dropdown.scrollTop + dropdown.clientHeight;
            const totalHeight = dropdown.scrollHeight;
            const threshold = 50; // pixels from bottom

            if (totalHeight - scrollPosition <= threshold) {
                if (this.debounceTimer) clearTimeout(this.debounceTimer);

                this.debounceTimer = setTimeout(() => {
                    if (this.fetchNewData) {
                        this.fetchNewData();
                    }
                }, 300);
            }
        },
    },

    mounted() {
        document.addEventListener('click', this.closeDropdown);
    },

    beforeUnmount() {
        document.removeEventListener('click', this.closeDropdown);
        if (this.debounceTimer) clearTimeout(this.debounceTimer);
    },
};
</script>


<style scoped>
.custom-select-wrapper {
    position: relative;
    width: 200px;
    font-family: Arial, sans-serif;
}

.custom-select {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.arrow {
    border: solid #000;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(45deg);
}

.dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 5px;
    z-index: 10;
    margin-top: 5px;
    height: 250px;
    overflow-y: scroll;
}

.dropdown-option {
    padding: 10px;
    cursor: pointer;
}

.dropdown-option:hover {
    background-color: #f0f0f0;
}

.selected {
    background-color: #e0e0e0;
    font-weight: bold;
}
</style>
