<template>
    <div>
        <div class="order-header">
            <h2>
                Order Data
            </h2>

            <div class="order-row">
                <div v-if="showFilters" class="order-controls">
                    <div class="order-group">
                        <label for="date-select">Date:</label>
                        <input type="date" v-model="selectedDate" id="date-select" @change="streamData">
                    </div>

                    <div class="order-group">
                        <label for="type-select">Order Type:</label>
                        <select v-model="selectedType" id="type-select" @change="streamData">
                            <option disabled value="">Select Order Type</option>
                            <option value="all">All</option>
                            <option value="BUY">BUY</option>
                            <option value="SELL">SELL</option>
                        </select>
                    </div>

                    <div class="order-group">
                        <label for="type-select">Order Status:</label>
                        <select v-model="selectedStatus" id="type-select" @change="streamData">
                            <option disabled value="">Select Order Status</option>
                            <option value="Cancelled">Cancelled</option>
                            <option value="Pending">Pending</option>
                            <option value="Filled">Filled</option>
                            <option value="Inactive">Inactive</option>
                            <option value="PreSubmitted">Pre Submitted</option>
                            <option value="Submitted">Submitted</option>
                        </select>
                    </div>
                </div>

                <button class="btn btn-primary" @click="toggleFilters">
                    <svg v-if="!showFilters" xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                        fill="currentColor" class="bi bi-search" viewBox="0 0 16 16">
                        <path
                            d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
                    </svg>
                    <svg v-if="showFilters" xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                        fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
                        <path
                            d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
                    </svg>
                </button>

                <div v-if="showFilters" class="d-none">
                    <button v-if="!downloadLoading" class="btn btn-primary" @click="downloadData">
                        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="18" fill="currentColor"
                            class="bi bi-cloud-download" viewBox="0 0 16 16">
                            <path
                                d="M4.406 1.342A5.53 5.53 0 0 1 8 0c2.69 0 4.923 2 5.166 4.579C14.758 4.804 16 6.137 16 7.773 16 9.569 14.502 11 12.687 11H10a.5.5 0 0 1 0-1h2.688C13.979 10 15 8.988 15 7.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 2.825 10.328 1 8 1a4.53 4.53 0 0 0-2.941 1.1c-.757.652-1.153 1.438-1.153 2.055v.448l-.445.049C2.064 4.805 1 5.952 1 7.318 1 8.785 2.23 10 3.781 10H6a.5.5 0 0 1 0 1H3.781C1.708 11 0 9.366 0 7.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383" />
                            <path
                                d="M7.646 15.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 14.293V5.5a.5.5 0 0 0-1 0v8.793l-2.146-2.147a.5.5 0 0 0-.708.708z" />
                        </svg>
                    </button>
                    <button v-if="downloadLoading" class="btn btn-primary" disabled>
                        <b-spinner small></b-spinner>
                    </button>
                </div>
            </div>
        </div>

        <hr />

        <div>
            <div class="float-end mb-3">
                <label for="items-per-page-select">Select Data Per Page :</label>
                <select v-model="itemsPerPage" class="ms-2 p-2" id="items-per-page-select" @change="selectDataPerPage">
                    <option v-for="option in itemsPerPageOptions" :key="option" :value="option">
                        {{ option }}
                    </option>
                </select>
            </div>

            <div class="data-table-container" @scroll="handleScroll" ref="tableContainer">
                <table class="data-table">
                    <thead>
                        <tr>
                            <th style="min-width: 170px;" class="sticky-col sticky-col-th-1">Date</th>
                            <th style="min-width: 80px;">Symbol</th>
                            <th style="min-width: 80px;">Exchange</th>
                            <th style="min-width: 80px;">Order Action</th>
                            <th style="min-width: 80px;">Order Type</th>
                            <th style="min-width: 80px;">Sec Type</th>
                            <th style="min-width: 80px;">Trading Class</th>
                            <th style="min-width: 80px;">Quantity</th>
                            <th style="min-width: 80px;">Strike Price</th>
                            <th style="min-width: 80px;">Right</th>
                            <th style="min-width: 100px;">Expiry Date</th>
                            <th style="min-width: 80px;">Avg Fill Price</th>
                            <th style="min-width: 80px;">Commission</th>
                            <th style="min-width: 80px;">Order Status</th>
                        </tr>
                    </thead>
                    <tbody v-if="!tableLoading">
                        <tr v-for="(item, index) in data" :key="item.time"
                            :class="(index % 2 === 0 ? 'evenRow' : 'oddRow')">
                            <td style="min-width: 170px;" class="sticky-col sticky-col-td-1">{{
                                formatDate(item.created_at) }}</td>
                            <td style="min-width: 80px;">{{ item.symbol }}</td>
                            <td style="min-width: 80px;">{{ item?.exchange }}</td>
                            <td style="min-width: 80px;">{{ item.orderAction }}</td>
                            <td style="min-width: 80px;">{{ item.orderType }}</td>
                            <td style="min-width: 80px;">{{ item.secType }}</td>
                            <td style="min-width: 80px;">{{ item.tradingClass }}</td>
                            <td style="min-width: 80px;">{{ item.quantity }}</td>
                            <td style="min-width: 80px;">{{ item.strike }}</td>
                            <td style="min-width: 80px;">{{ item.right ? item.right === "P" ? 'PUT' : 'CALL' : '-' }}
                            </td>
                            <td style="min-width: 100px;">{{ item.expiry !== 'Invalid date' ? `${item.expiry.slice(6,
                                8)}/${item.expiry.slice(4, 6)}/${item.expiry.slice(0, 4)}` : item.expiry }}</td>
                            <td style="min-width: 80px;">{{ item.avgFillPrice ? item.avgFillPrice : '-' }}</td>
                            <td style="min-width: 80px;">{{ item.commission ? item.commission : '-' }}</td>
                            <td style="min-width: 80px;">{{ item.orderStatus }}</td>
                        </tr>
                        <tr v-if="data.length === 0">
                            <td colspan="14" style="text-align: center; padding: 20px">No Data Available</td>
                        </tr>
                    </tbody>
                    <tbody v-if="tableLoading">
                        <tr>
                            <td colspan="14" style="text-align: center; padding: 20px">
                                <b-spinner small></b-spinner>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class="pagination-controls mt-3">
                <div class="order-group me-2">
                    <select v-model="itemsPerPage" id="items-per-page-select" @change="selectDataPerPage">
                        <option v-for="option in itemsPerPageOptions" :key="option" :value="option">
                            {{ option }}
                        </option>
                    </select>
                </div>
                <button class="btn btn-primary" @click="prevPage" :disabled="currentPage === 1">Previous</button>
                <span class="mx-3">Page {{ currentPage }} of {{ totalPages }}</span>
                <button class="btn btn-primary" @click="nextPage"
                    :disabled="currentPage === totalPages || totalPages === 0">Next</button>
            </div>
        </div>
        <div class="bottom-padding"></div>
    </div>
</template>

<script>
import reconnectSocket from '@/plugins/socket';
import moment from 'moment-timezone';
import { useToast } from 'vue-toast-notification';

// Initialize toast notifications
const $toast = useToast();

export default {
    name: 'OrderData',
    data() {
        return {
            data: [],
            socket: null,
            totalData: 0,
            selectedDate: '',
            selectedStatus: '',
            selectedOrder: '',
            showFilters: false,
            itemsPerPage: 50,
            currentPage: 1,
            itemsPerPageOptions: [50, 100, 250, 500, 1000, 2500, 5000, 10000],
            tableLoading: false,
            downloadLoading: false,
        };
    },
    computed: {
        totalPages() {
            return Math.ceil(this.totalData / this.itemsPerPage);
        }
    },
    methods: {
        toggleFilters() {
            this.showFilters = !this.showFilters;
            this.resetFilters();
            this.streamData();
        },
        resetFilters() {
            this.selectedDate = '';
            this.selectedType = '';
            this.selectedStatus = '';
        },
        async streamData() {
            let inputObj = {
                skip: (this.currentPage - 1) * this.itemsPerPage,
                limit: this.itemsPerPage
            }

            if (this.selectedDate) {
                inputObj.date = moment(this.selectedDate).format('YYYY-MM-DDTHH:mm:ss')
            }

            if (this.selectedType) {
                inputObj.orderAction = this.selectedType
            }

            if (this.selectedStatus) {
                inputObj.orderStatus = this.selectedStatus
            }

            this.socket.emit('orderData', inputObj, (response) => {
                // console.log(response)
                this.data = response?.data?.data
                this.totalData = response?.data?.count;
            })
        },
        async downloadData() {
            this.downloadLoading = true;
            const params = new URLSearchParams();

            if (this.selectedDate) {
                params.append('date', moment(this.selectedDate).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'));
            }

            if (this.selectedType) {
                params.append('orderAction', this.selectedType);
            }

            const queryString = params.toString();
            const url = `${process.env.VUE_APP_BACKEND_URL}/api/downloadData?${queryString}`;

            try {
                const response = await fetch(url);
                if (response.ok) {
                    const blob = await response.blob();
                    const link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = 'data.csv';
                    link.click();
                } else {
                    $toast.error('Failed to download data: ' + response.statusText);
                }
            } catch (error) {
                $toast.error('Error downloading data: ' + error.message);
            } finally {
                this.downloadLoading = false;
            }
        },
        formatDate(date) {
            return moment(date).format('MM/DD/YYYY hh:mm:ss');
        },
        nextPage() {
            if (this.currentPage < this.totalPages) {
                this.currentPage++;
                this.streamData();
            }
        },
        prevPage() {
            if (this.currentPage > 1) {
                this.currentPage--;
                this.streamData();
            }
        },
        selectDataPerPage() {
            this.currentPage = 1; // Reset to first page on items per page change
            this.streamData();
        },
        handleScroll() {
            // Optional: Implement infinite scrolling if required
        },
    },
    mounted() {
        this.socket = reconnectSocket()

        this.streamData()

        this.socket.on('orderStatus', data => {
            if (data) {
                this.streamData()
            }
        })

        this.socket.on('orderError', data => {
            if (data) {
                this.streamData()
            }
        })
    },
};
</script>

<style scoped>
h2 {
    font-size: 22px;
    margin-bottom: 0px;
    margin-right: 15px;
}

svg {
    margin-bottom: 4px;
}

.order-header {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}

.order-controls {
    display: flex;
    gap: 15px;
    margin-top: 20px;
    align-items: flex-end;
    justify-content: space-between;
}

.order-row {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    gap: 15px;
}

.order-group {
    display: flex;
    flex-direction: column;
}

.order-group label {
    margin-bottom: 5px;
    font-weight: bold;
}

.order-group input,
.order-group select {
    padding: 6px;
}

#type-select,
#start-difference,
#end-difference {
    width: 135px;
}

.search-button:hover {
    background-color: #0056b3;
}

/* Scrollable table styles */
.data-table-container {
    width: 100%;
    height: 535px;
    overflow-y: auto;
    margin-top: 20px;
    border: 1px solid #ddd;
}

.data-table {
    width: 100%;
    height: 100%;
}

.data-table thead {
    height: 30px;
}

.data-table tbody {
    height: 423px;
    padding-top: 30px;
}

.data-table th {
    text-align: center;
}

.data-table th,
.data-table td {
    width: auto;
    padding: 8px;
    outline: 1px solid #ddd;
}

/* Sticky header */
.data-table th {
    background-color: yellow;
    position: sticky;
    top: 0;
    z-index: 2;
}

/* Striped rows */
.data-table tbody .evenRow {
    background-color: #E0E0E0;
    /* Light gray for even rows */
}

.data-table tbody .oddRow {
    background-color: #FFFFFf;
    /* Light gray for even rows */
}

.sticky-col th {
    z-index: 3;
}

/* Sticky first two columns */
.sticky-col {
    position: sticky;
    z-index: 1;
}

.evenRow .sticky-col {
    background-color: #E0E0E0;
}

.oddRow .sticky-col {
    background-color: #FFFFFf;
}

.sticky-col-th-1 {
    left: 0;
    z-index: 3 !important;
}

.sticky-col-th-2 {
    left: 100px;
    z-index: 3 !important;
}

.sticky-col-td-1 {
    left: 0;
    z-index: 1 !important;
}

.sticky-col-td-2 {
    left: 100px;
    z-index: 1 !important;
}

/* Pagination controls */
.pagination-controls {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 30px;
}

/* Media query for responsiveness */
@media (max-width: 1200px) {
    .order-header {
        display: block;
    }

    .order-row {
        display: flex;
        justify-content: flex-end;
    }
}
</style>
